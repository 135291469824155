import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { NzUploadChangeParam, NzUploadFile, UploadFilter } from 'ng-zorro-antd/upload';
import { formExplain, MIMETYPES, URL_API, URL_FILE } from '../../../utils/const';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';


@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.less']
})
export class UploadFileComponent implements OnInit {

  @Input() form;
  @Input() attribute;
  @Input() initial;
  @Output() callback = new EventEmitter();

  formExplain = formExplain;

  uploadControl: FormControl;
  fileList: NzUploadFile[] = [];
  isValidUpload: boolean = false;
  action: string = `${URL_API}/storage/put`;
  header: {
    Accept: string;
    Authorization: string;
  }

  headerUpload:HttpHeaders;

  limit: number = 1;
  values: string[] = [];
  fileTypes = null;

  isUploadReset = false;

  template: {
    label: string;
    title?: string;
    link?: string;
    endpoint?: {
      url: string;
      filename: string;
    };
  }

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user'));

    this.header = {
      'Accept': 'application/x.edutec.v1+json',
      'Authorization': user != null ? user.access_token : '',
    }

    this.headerUpload = new HttpHeaders(this.header);

    this.uploadControl = this.form.get(this.attribute.key) as FormControl;
    this.limit = _.result(this.attribute, 'formItem.pars.limit') || 1;
    this.fileTypes = _.result(this.attribute, 'formItem.pars.fileTypes') || null;
    this.template = _.result(this.attribute, 'formItem.pars.template-upload')

    if (!_.isEmpty(this.initial)) {
      this.files(this.initial.value);
    }

    if (this.uploadControl.value) {
      this.files(this.uploadControl.value);
    }

    this.uploadControl.valueChanges.subscribe(value => {
      this.files(value);
    });
  }


  files(value): void {
    if (!value) {
      this.fileList = [];
      this.values = [];
    } else {
      const values = this.limit == 1 ? [value] : value;

      if (values != this.values) {
        this.fileList = [];
        this.values = values;

        this.values.map((item, index) => {
          this.fileList.push({
            uid: `${index}`,
            name: item,
            status: 'done',
            url: `${URL_FILE}${item}`,
            response: item
          })
        })
      }
    }
  }

  handleChange(info: NzUploadChangeParam): void {
    let fileList = info.fileList;

    fileList = fileList.slice(-this.limit);

    this.fileList = fileList.map(file => {
      if (file.response) {
        file.name = file.response.key
        file.url = `${URL_FILE}${file.response.key}`
      }
      return file;
    });

    if (info.file.status === 'done') {
      this.values.push(info.file.response.key);

      if (this.limit == 1) {
        this.uploadControl.setValue(info.file.response.key);
      } else {
        this.uploadControl.setValue(this.values);
      }
    }
  }

  handleRemove = (file: NzUploadFile) => {
    this.values = this.values.filter((v) => v !== file.name);
    this.fileList = this.fileList.filter((f) => f.name !== file.name);

    if (this.limit === 1) {
      this.uploadControl.setValue(this.values[0]);
    } else {
      this.uploadControl.setValue(this.values);
    }

    return true;
  };

  filters: UploadFilter[] = [
    {
      name: 'type',
      fn: (fileList: NzUploadFile[]) => {

        if (this.fileTypes) {

          const tiposPermitidos = _.map(this.fileTypes, tipo => {
            const tipoExpanded = _.find(MIMETYPES, { 'type': tipo });
            return tipoExpanded ? tipoExpanded.expanded : null;
          });

          const filterFiles = fileList.filter(w => ~tiposPermitidos.indexOf(w.type));
          if (filterFiles.length !== fileList.length) {

            this.formExplain.errorUpload = `Somente os tipos a seguir são permitidos: ${_.join(this.fileTypes, ', ')}`;
            this.uploadControl.markAsDirty();
            this.uploadControl.setErrors({ errorserve: true });
            return filterFiles;
          }
        }
        return fileList;
      }
    },
    {
      name: 'limit',
      fn: (fileList: NzUploadFile[]) => {
        if ((this.limit - this.values.length) < fileList.length) {
          this.formExplain.errorUpload = `Não é permitido carregar mais que ${this.limit} arquivo(s).`;
          this.uploadControl.markAsDirty();
          this.uploadControl.setErrors({ errorserve: true });
          return [];
        }
        return fileList;
      }
    },
    {
      name: 'size',
      fn: (fileList: NzUploadFile[]) => {
        fileList.forEach(file => {
          const isLt10M = file.size / 1024 / 1024 <= 10;

          if (!isLt10M) {
            this.uploadControl.markAsDirty();
            this.uploadControl.setErrors({ nosize: true });

            return [];
          }
        });

        return fileList;
      }
    }
  ];
}
