import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import * as _ from 'lodash';
import { ApiService } from '../../shared/services/api.service';
import { DS_CHAVE_PROFESSOR, URL_API_SEARCH } from '../utils/const';

import { filter } from 'rxjs/operators';

@Injectable()
export class PermissionsService {

  user: any = {};
  pnldFiltroBase: any = {};

  edital: any = {};
  objeto: any = {};

  menu: any[] = [];
  menuDashboard: any[] = [];

  permissions: any[] = [];
  lasUrl: string;

  acoes: { [key: string]: boolean };

  constructor(private apiService: ApiService, private router: Router) {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe( (e: NavigationEnd) => this.lasUrl = e.url);
  }

  /**
 * Este método está depreciado e será removido em versões futuras.
  * Use o método `getMenuHome` em vez disso.
  * @deprecated Use `getMenuHome` em vez disso.
  */
  menuPermissions(): void{
    // Filtrar para obter somente os módulos com permissão de leitura,
    // para serem exibidos na lista de Menu do usuário logado.
    // const readModulesPermission = _.filter(permissoes, { st_leitura: 'S' });
    const readModulesPermission = _.filter(this.permissions, (per) => {
      return per.st_leitura === 'S'
        && !_.isEmpty(per.modulo) //Caso algum módulo seja excluído logicamente, porém ainda exista a permissão, ela não deve ser retornada.
        && !_.isEmpty(per.modulo.modulo_grupo) //Caso algum módulo pertença a um grupo de módulos excluído logicamente, não deve ser retornado.
    });

    // Map para retirar o objeto das permissões e ficar somente com os objetos.
    // MODULO e seus filhos.
    const readingModules = _.sortBy(_.map(readModulesPermission, 'modulo'), ['nu_ordem']);

    // Filtro para obter os menus que não tem grupo e exibir antes dos grupos.
    const menusWithoutGroup = _.filter(readingModules, (item) => {
      return !item.modulo_grupo || !item.modulo_grupo.co_modulo_grupo
    });

    // Filtro para obter os menus que tem grupo.
    const groupMenus = _.filter(readingModules, (item) => {
      return item.modulo_grupo
    });

    // Filtro com todos os grupos de menus apenas (sem os menus).
    const grupos = _.map(groupMenus, 'modulo_grupo');

    // Reduce para reagrupar os objetos, colocando os menus dentro
    // de um array cujo índice é o código do grupo do módulo (co_modulo_grupo)
    // O resultado é um array, cujos elementos são arrays representando cada
    // grupo de módulo e dentro deles os respectivos módulos / menu.
    const groupsMenusWithItems = _.reduce(groupMenus, function (result, value, key) {
      (result[value.modulo_grupo.co_modulo_grupo] || (result[value.modulo_grupo.co_modulo_grupo] = [])).push(
        {
          title: value.ds_titulo,
          key: value.ds_rota_api,
          icon: value.ds_icone
        }
      );
      return result;
    }, []);

    // Adicionando menus que não tem grupo.
    menusWithoutGroup.map((item) => {
      this.menu.push(
        {
          title: item.ds_titulo,
          key: item.ds_rota_api,
          icon: item.ds_icone
        }
      )
    });

    // Adicionando menus que possue grupos.
    groupsMenusWithItems.map((subMenu, co_modulo_grupo) => {
      const grupo = _.find(grupos, { 'co_modulo_grupo': co_modulo_grupo })
      this.menu.push(
        {
          title: grupo.ds_titulo,
          active: false,
          icon: grupo.ds_icone,
          subMenu: subMenu
        }
      )
    });

  }


  handleLogin(path: string = this.lasUrl){
    this.router.navigate(['/login']);
  }

  restPermissions(){
    this.menu = [];
    this.permissions = [];
  }

  isPermissions(moduleKey = null): Promise<boolean>{
    this.user = JSON.parse(localStorage.getItem('user'));
    this.pnldFiltroBase = JSON.parse(localStorage.getItem("@pnld_filtro_base"));

    console.log(this.permissions);

    return new Promise<boolean>((resolve, reject) => {
      if(this.permissions.length <= 0) {
        this.apiService.post(`modulo-permissao/modulos-usuario`, {
          co_edital: this.pnldFiltroBase?.edital?.value,
          co_objeto: this.pnldFiltroBase?.objeto?.value
        })
        .subscribe(resp => {
          if(resp['status'] === "OK") {
            // Obter as permissões do usuário logado (menus e privilégios)
            this.permissions = _.result(resp, 'data');

            if(moduleKey != null)
              resolve(this.isPermissionsModule(moduleKey));
            else resolve(true);

          } else {
            console.log('modulo-permissao/modulos-usuario FAIL');
            console.log(resp);
            resolve(false);
          }
        }, error => {
          if(error.status === 401){
            this.handleLogin();         
          }
          else
            console.log(error);
          resolve(false);
        });
      } else {
        resolve(this.isPermissionsModule(moduleKey));
      }
    });
  }

  isPermissionsModule(moduleKey): boolean{
    if (_.isEmpty(moduleKey)) {
      return false;
    }

    const permissionOnModule = _.find(this.permissions, ['modulo.ds_chave', moduleKey]);

    // Se for vazio, nao tem permissão.
    if (_.isEmpty(permissionOnModule)) {

      return false;
    } else {

      const items = permissionOnModule?.modulo_acaos?.map(acao => acao['ds_chave']);
      this.acoes = items.reduce((accumulator, currentValue) => (
        (accumulator[currentValue] = true), 
        accumulator
      ),{});

    }

    return true;
  }

  getModule(co_modulo_permissao): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`modulo-permissao/${co_modulo_permissao}`)
        .subscribe(
          resp => {
              return resolve(resp);
          },
          error => {
            return reject(error);
          }
        );
      });
  }

  getModulesByPerfil(params): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`modulo-permissao${URL_API_SEARCH}`, { filters: [{ model: '', filter: params}], limit: -1 })
        .subscribe(
          resp => {
              return resolve(resp);
          },
          error => {
            return reject(error);
          }
        );
      });
  }
 
  addModuleToProfile(params): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`modulo-permissao`, params)
        .subscribe(
          resp => {
              return resolve(resp);
          },
          error => {
            return reject(error);
          }
        );
      });
  }

  removeModuleToProfile(moduleProfileId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.delete(`modulo-permissao/${moduleProfileId}`)
        .subscribe(
          resp => {
              return resolve(resp);
          },
          error => {
            return reject(error);
          }
        );
      });
  }

  changePermissions(co_modulo_permissao, params): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.put(`modulo-permissao/${co_modulo_permissao}`, params)
        .subscribe(
          resp => {
              return resolve(resp);
          },
          error => {
            return reject(error);
          }
        );
      });
  }

  getActions(params): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`modulo-acao${URL_API_SEARCH}`, { filters: [{ model: '', filter: params}], limit: -1 })
        .subscribe(
          resp => {
              return resolve(resp);
          },
          error => {
            return reject(error);
          }
        );
      });
  }

  getModulosUsuarioMenuDashboard(coEdital, coObjeto): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        this.apiService.post('modulo-permissao/modulos-menu-dashboard', {
            co_edital: coEdital,
            co_objeto: coObjeto
        }).subscribe(
            response => {
                return resolve(response);
            }, error => {
                return reject(error);
            }
        );
    });
  }

  getMenuHome(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        this.apiService.get('modulo-permissao/menu').subscribe(
            response => {
                return resolve(response);
            }, error => {
                return reject(error);
            }
        );
    });
  }

}
