export const HELPER_MESSAGE = {
  MSG000: 'Sessão inválida. Faça o login novamente.',
  MSG001: 'Registro incluído com sucesso.',
  MSG002: 'Registro alterado com sucesso.',
  MSG003: 'Registro(s) excluído(s) com sucesso.',
  MSG004: (name: string) => `O campo ${name} é de preenchimento obrigatório.`,
  MSG005: (name: string) => `O campo ${name} deve ser válido.`,
  MSG006: 'Nenhum registro encontrado.',
  MSG013: 'Confirma a exclusão do(s) registro(s)?',
  MSG016: 'Operação realizada com sucesso.',
  MSG024: 'Deseja realmente alterar a situação do registro?',
  MSG053: 'O CPF informado é inválido',
  MSG107: (min: number) => `O mínimo de caracteres para as respostas é de ${min} caracteres.`,
  MSG108: (max: number) => `O máximo de caracteres para as respostas é de ${max} caracteres.`,
  MSG114: 'As senhas não conferem.',
  MSG072: 'Ao excluir um item que possui subitens, todos os seus subitens também serão excluídos. Confirma a exclusão?',
  MSG073: 'É necesário preencher o campo categoria.',
};